import { useSession } from 'next-auth/react';
import Head from 'next/head';
import React from 'react';
import FeatureGridList from '../ui/FeatureGridList';
import Footer from '../ui/Footer';
import GuestSessionErrorNotification from '../ui/GuestSessionErrorNotification';
import Hero from '../ui/Hero';
import Navbar from '../ui/Navbar';
import Pricing from '../ui/Pricing';
import Functionality from './Functionality';

const features = [
	{
		name: 'Account Management',
		description: 'Manage your account and settings.',
	},
	{
		name: 'Custom Rooms',
		description: 'Create your own custom rooms and assign them to your team.',
	},
	{
		name: 'Role Based Access',
		description:
			'Create roles and allow some users to use specific actions on your rooms.',
	},
	{
		name: 'Screen Sharing',
		description: 'Easily share your screen with your team at any time.',
	},
	{
		name: 'Full AV Control',
		description:
			'Control all of your Audio and Video settings all in one place.',
	},
	{
		name: 'Team Management',
		description:
			'Easily manage your current team and manage any invites your team has received.',
	},
	{
		name: 'Room Categories',
		description:
			'Each custom room can be assigned to a category to help organize your rooms.',
	},
	{
		name: 'Real Time Updates',
		description:
			'Get real time updates on your rooms and know where your team members are at.',
	},
];

export function Home({ guestLinkError }: { guestLinkError: string | null }) {
	const { data: session } = useSession();
	return (
		<div>
			<Head>
				<title>Pong</title>
				<meta
					property="og:description"
					content="Colaborate in realtime with pong"
				/>
				<meta
					property="og:image"
					content="https://i.ibb.co/gr3Z7dW/OG-Desktop.png"
				/>
				<meta
					property="og:title"
					content="pong | A better way to sell from anywhere."
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://www.ponghq.com/" />
			</Head>
			<div>
				<Navbar session={session} />
				{guestLinkError && (
					<GuestSessionErrorNotification errorMessage={guestLinkError} />
				)}
				<div>
					<Hero session={session} />
				</div>
				<div>
					<Functionality />
				</div>
				<div>
					<Footer />
				</div>
			</div>
		</div>
	);
}
