import { Home } from '../components/Home';

export async function getServerSideProps(ctx: any) {
	const { guestLink }: { guestLink: string | null } = ctx.query;

	if (!guestLink) {
		return {
			props: {
				guestLinkError: null,
			},
		};
	} else {
		return {
			props: {
				guestLinkError: guestLink,
			},
		};
	}
}

export default Home;
