/* eslint-disable @next/next/no-img-element */
import { Session } from 'next-auth';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import React from 'react';

export default function Hero({ session }: { session: Session | null }) {
	const router = useRouter();
	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<div className="flex items-center justify-center">
				<div className="flex-1 text-center lg:text-left">
					<div className="p-12">
						<div className="my-6">
							<span className="inline-flex items-center px-2.5 py-1 rounded-lg text-sm font-bold bg-red-400 text-white">
								BETA
							</span>
						</div>
						<h1 className="text-4xl font-bold text-blue-600">
							A better way to
						</h1>
						<h1 className="text-4xl font-bold text-blue-600">
							connect from anywhere
						</h1>
						<p className="mt-4 text-xl text-gray-500 tracking-wide font-light">
							pong is free for all. Get connected <br /> and never miss a
							moment.
						</p>
						{session ? (
							<button
								type="button"
								onClick={() => router.push('/rooms')}
								className="inline-flex items-center px-4 py-4 mt-6 text-base font-semibold rounded-full focus:outline-none bg-blue-600 hover:bg-blue-700 text-white"
							>
								Go to your Rooms
							</button>
						) : (
							<button
								type="button"
								onClick={() => signIn()}
								className="inline-flex items-center px-6 py-3 mt-6 font-semibold rounded-full focus:outline-none bg-blue-600 hover:bg-blue-700 text-white text-sm"
							>
								Get Started
							</button>
						)}
					</div>
				</div>
				<div className="flex-1 hidden lg:block">
					<div className="p-12">
						<img
							src="https://i.ibb.co/fr2CDM4/Screen-Shot-2022-11-09-at-11-27-53-AM.png"
							alt="hero"
							className="rounded-xl"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
