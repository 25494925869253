/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router';
import React from 'react';

export default function Functionality() {
	const router = useRouter();
	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-32">
			<div className="flex flex-col gap-8 items-center justify-center text-center">
				<div className="flex items-center justify-center">
					<img
						src="https://images.unsplash.com/photo-1616587226960-4a03badbe8bf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80"
						alt=""
						className="rounded-xl w-3/4"
					/>
				</div>
				<div>
					<h1 className="text-4xl font-bold tracking-wide text-blue-500">
						Bring The Functionality Of The Office <br /> To Your Home With Video
						Meetings
					</h1>
				</div>
				<div>
					<p className="text-sm text-gray-500">
						We provide an easy for you and your coworkers to connect with one
						another without always needing to be in the office.
					</p>
				</div>
				<div>
					<button
						onClick={() => router.push('/rooms')}
						className="inline-flex items-center px-6 py-3 mt-6 font-semibold rounded-full focus:outline-none bg-red-400 hover:bg-red-500 text-white text-sm"
					>
						Create / Join your Team
					</button>
				</div>
				<div className="flex items-center justify-center mt-12">
					<div className="flex-1 flex items-center justify-center">
						<img
							src="https://i.ibb.co/fr2CDM4/Screen-Shot-2022-11-09-at-11-27-53-AM.png"
							alt=""
							className="rounded-xl w-3/4"
						/>
					</div>
					<div className="flex-1 flex flex-col items-start justify-start gap-4">
						<h1 className="text-5xl font-bold text-blue-500 text-left">
							In This Together. <br /> Keeping You Securely Connected Wherever
							You Are.
						</h1>
						<p className="text-sm text-gray-500 text-left">
							Bring the functionality of the office to your home with video
							meetings, phone calls, whiteboarding, and annotation on your
							personal collaboration device.
						</p>
						<button
							onClick={() => router.push('/rooms')}
							className="inline-flex items-center px-6 py-3 mt-6 font-semibold rounded-full focus:outline-none bg-red-400 hover:bg-red-500 text-white text-sm"
						>
							Why Pong?
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
