import React from 'react';

const navigation = {
	main: [
		{ name: 'About', href: '#' },
		{ name: 'Blog', href: '#' },
		{ name: 'Jobs', href: '#' },
		{ name: 'Press', href: '#' },
		{ name: 'Accessibility', href: '#' },
		{ name: 'Partners', href: '#' },
	],
};

export default function Footer() {
	return (
		<footer>
			<div className="mx-auto mt-12 py-20 px-4 overflow-hidden sm:px-6 lg:px-8 bg-blue-600">
				<nav
					className="-mx-5 -my-2 flex flex-wrap justify-center"
					aria-label="Footer"
				>
					{navigation.main.map((item) => (
						<div key={item.name} className="px-5 py-2">
							<a
								href={item.href}
								className="text-base text-gray-50 hover:text-gray-100"
							>
								{item.name}
							</a>
						</div>
					))}
				</nav>
				<p className="mt-8 text-center text-base text-gray-100">
					&copy; {new Date().getFullYear()} Pong, Inc. All rights reserved.
				</p>
			</div>
		</footer>
	);
}
